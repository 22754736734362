import React, { useState } from "react"
import { PageLayout } from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import TextSectionBackground from "../components/TextSectionBackground"
import { TextCenter, Row } from "../components/General"
import { SectionHeader, SubSectionHeader } from "../components/Typography"
import Blog from "../components/Blog"

const LatestNews = () => {
    const data = useStaticQuery(graphql`
      query {
        allContentfulBlog(limit: -1, sort: { fields: time, order: DESC }) {
          nodes {
            header
            time
            slug
            shortText
            contentful_id
            image {
              fixed(width: 350, height: 225) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
    `)
    return (
        <PageLayout>
            <SEO 
            title="Safari News" 
            description="Learn about what's been happening at Mavela Gane Lodge. From conservation news to anything safari, stay informed by reading our blog." 
            />
            <TextSectionBackground>
                <TextCenter pa="1rem 0">
                <SectionHeader>Latest News</SectionHeader>
                <SubSectionHeader>Whats been happening at Mavela</SubSectionHeader>
                <Row fw="wrap"></Row>
                </TextCenter>
                <Blog allContentfulBlog={data.allContentfulBlog}></Blog>
            </TextSectionBackground>
        </PageLayout>
      
    )
  }

  export default LatestNews